<template>
    <div>
        <button type="button" class="btn btn-success"
            rel="tooltip"
            data-placement="top"
            @click="showModalCafc = true;"
        >
            <i class="nav-icon i-Add font-weight-bold" style="padding: 0px"></i>
        </button>
        <b-modal
        id="modalCafc"
        title="Nuevo: CAFC"
        v-model="showModalCafc"
        size="lg"
        hide-footer>
            <div class="modal-body">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <b class="h5">Facturacion</b>
                            <multiselect
                                :options="facturacions"
                                track-by="nombre"
                                label="nombre"
                                placeholder="Seleccionar"
                                deselectLabel=""
                                selectLabel="Seleccionar"
                                :searchable="true">
                            </multiselect>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <b class="h5">Documento:</b>
                            <multiselect
                                :options="documentos"
                                track-by="nombre"
                                label="nombre"
                                placeholder="Seleccionar"
                                deselectLabel=""
                                selectLabel="Seleccionar"
                                :searchable="true">
                            </multiselect>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <b class="h5">Tipo Factura/Documento:</b>
                            <input type="text" class="form-control" disabled>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="form-group">
                            <b class="h5">Sector:</b>
                            <input type="text" class="form-control" disabled>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <b class="h5">Descripcion:</b>
                            <input type="text" class="form-control" disabled>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <b class="h5">Sector:</b>
                            <input type="text" class="form-control">
                        </div>
                    </div>
                    <div class="col-sm-8">
                        <div class="form-group">
                            <b class="h5">Rango de Uso:</b>
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">
                                        Desde:
                                    </label>
                                    <div class="col-sm-3">
                                        <vue-numeric 
                                            v-model="importeInicial"
                                            v-bind:minus="false" v-bind:min="0" 
                                        class="form-control"/>
                                    </div>
                                    <label class="col-sm-2 col-form-label">
                                        Hasta:
                                    </label>
                                    <div class="col-sm-3">
                                        <vue-numeric 
                                        v-model="importeFinal"
                                            v-bind:minus="false" v-bind:min="0" 
                                        class="form-control"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="text-right">
                    <button class="btn btn-secondary" @click="showModalCafc = false;">Cancelar</button>
                    <button class="btn btn-success" style="margin-left:11px;">Guardar</button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

import VueNumeric from 'vue-numeric';

export default {
    name: 'theModalCafc',
    components: {
        Multiselect,
        VueNumeric,
    },
    data() {
        return {
            showModalCafc: false,
            facturacions: [],
            documentos: [],
            tipoFacturas: [],
            sectors: [],
            importeInicial: 0,
            importeFinal: 0,
        }
    }
}
</script>