<template>
    <div>
        <div class="col-sm-12 py-3 px-2">
            <div class="row">
                <div class="col-sm-6">
                    <div class="row mb-2">
                        <div class="col-sm-12">
                            <b> <label class="col-form-label">Computariza en Linea</label> </b>
                        </div>
                        <div class="col-sm-12 text-light">
                            <label for="">Sector(1) Factura Compra-Venta 101D9A > 1 A 1000 </label>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-12">
                            <b> <label class="col-form-label">Electronica en Linea</label> </b>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="row mb-2 text-right" style="margin:auto;">
                        <div class="col-sm-12">
                            <theModalCafc/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import theModalCafc from './theModalCAFC.vue';

export default {
    name: 'tabCafc',
    data() {
        return {

        };
    },
    components: {
        theModalCafc,
    },
    methods: {},
    computed: {},
    watch: {},

}
</script>

<style scoped>

</style>