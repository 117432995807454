<template>
    <div>
        <div class="col-sm-12 py-3 px-2">
            <div class="row">
                <div class="col-sm-6">
                    <div class="row mb-2">
                        <div class="col-sm-4 pr-0 text-right">
                            <label class="col-form-label">Dominio:</label>
                        </div>
                        <div class="col-sm-7">
                            <input type="text"
                                class="form-control"
                                autocomplete="off"
                                v-model="dominio"
                            >
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-4 pr-0 text-right">
                            <label class="col-form-label">Ciudad:</label>
                        </div>
                        <div class="col-sm-7">
                            <multiselect
                                v-model="ciudadSelected"
                                :options="ciudades"
                                :loading="loadingCiudad"
                                track-by="id"
                                label="nombre"
                                placeholder="Seleccionar"
                                deselectLabel=""
                                selectLabel="Seleccionar"
                                :searchable="true">
                            </multiselect>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-4 pr-0 text-right">
                            <label class="col-form-label">Tipo:</label>
                        </div>
                        <div class="col-sm-7">
                            <multiselect
                                v-model="tipoSelected"
                                :options="tipos"
                                track-by="nombre"
                                label="nombre"
                                placeholder="Seleccionar"
                                deselectLabel=""
                                selectLabel="Seleccionar"
                                :searchable="true">
                            </multiselect>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="row mb-2">
                        <div class="col-sm-4 pr-0 text-right">
                            <label class="col-form-label">URL de Consulta de Factura:</label>
                        </div>
                        <div class="col-sm-4">
                            <input type="text"
                                class="form-control"
                                autocomplete="off"
                                value="gemgloo.com/"
                                disabled
                            >
                        </div> 
                        <div class="col-sm-3">
                            <input type="text"
                                class="form-control"
                                autocomplete="off"
                            >
                        </div> 
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-4 pr-0 text-right">
                            <label class="col-form-label">Titulo de Asunto para Mail:</label>
                        </div>
                        <div class="col-sm-7">
                            <input type="text"
                                class="form-control"
                                autocomplete="off"
                                v-model="asuntoMail"
                            >
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-4 pr-0 text-right">
                            <label for="email" class="col-form-label">Mail (Remitente):</label>
                        </div>
                        <div class="col-sm-7">
                            <input type="email"
                                class="form-control"
                                autocomplete="off"
                                v-model="mailRemitente"
                            >
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-4 pr-0 text-right">
                            <label class="col-form-label">Zona Horaria:</label>
                        </div>
                        <div class="col-sm-5">
                            <multiselect
                                v-model="zonaHorariaSelected"
                                :options="zonaHorarias"
                                track-by="id"
                                label="descripcion"
                                placeholder="Seleccionar"
                                deselectLabel=""
                                selectLabel="Seleccionar"
                                :searchable="true">
                            </multiselect>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { get, sync } from 'vuex-pathify';
import Multiselect from 'vue-multiselect'


export default {
    name: 'tabSitioWeb',
    components: {
        Multiselect,
    },
    data() {
        return {};
    },
    methods: {},
    computed: {
        ...get("configuracion/sitioWeb", [
            "tipos",
            "zonaHorarias",
            "loadingCiudad",
        ]),
        ...sync("configuracion/sitioWeb", [
            'tipoSelected',
            'zonaHorariaSelected',
            "ciudadSelected",
            "ciudades",
            "mailRemitente",
            "asuntoMail",
            "dominio",
        ])
    },
    watch: {},
}
</script>

<style scoped>

</style>